<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    :size="size"
  >
    <div style="padding: 0 20px">
      <el-row>
        <el-col class="item-title"
          ><span>课程名称：</span>
          <span class="item-text2"> {{ item.title }}</span></el-col
        >
        <el-col class="item-title"
          ><span>课程分类：</span>
          <span class="item-text2"> {{ item.courseGoodsName }}</span></el-col
        >
      </el-row>
      <el-row>
        <el-tabs class="tb-tabs" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="课程目录" name="Info" >
            <Info ref="Info" :id="id"></Info>
          </el-tab-pane>
          <el-tab-pane label="在读班级" name="Class">
            <Class ref="Class" :id="id"></Class>
          </el-tab-pane>
          <el-tab-pane label="在读学员" name="Student" >
            <Student ref="Student" :id="id"></Student>
          </el-tab-pane>
        </el-tabs>
        
      </el-row>
    </div>
  </el-drawer>
</template>
<script>
import Info from "@/components/infoDetail/Info";
import Class from "@/components/infoDetail/Class";
import Student from "@/components/infoDetail/Student";

export default {
  props: {
    title: {
      type: String,
      default: () => "详情",
    },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      item: {},
      activeName: "Info",
      size:"800px",
      id:""
    };
  },
  components: {
    Info,
    Class,
    Student,
  },
  methods: {
    handleClose() {
      this.drawer = false;
      this.$refs.Info.pagination.pageNum = 1;
      this.$refs.Info.pagination.pageSize = 10;
      this.$refs.Class.pagination.pageNum = 1;
      this.$refs.Student.pageInfo.pageSize = 10;
      this.$refs.Student.pageInfo.pageNum = 1;
      this.$refs.Class.pagination.pageSize = 10;

    },
    initData(item) {
      this.item = { ...item };
      this.id = this.item.id
    },
    handleClick(tab, event) {
        if(this.activeName === 'Info' ) {
          this.$nextTick(()=>{
            this.$refs.Info.getCourseUnitLists(this.item.id)
          })
        } else if (this.activeName === 'Class' ) {
          this.$refs.Class.getClassAll(this.item.id)
        } else if (this.activeName === 'Student' ) {
          this.$refs.Student.getListHandler(this.item.id)

        }
      }
  },
};
</script>
<style lang="scss" scoped>
.item-title {
  height: 40px;
}
.item-text1 {
  font-weight: bold;
}
@import "../../../style/table.scss";
.back-tit {
  margin-left: 0px !important;
}
.row-bg {
  padding: 15px !important;
  margin-top: 15px !important;
}
</style>
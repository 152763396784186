<template>
  <div class="table">
    <!-- 表格 -->
    <!-- <el-card shadow="never"> -->
      <div slot="header" class="flex" style="align-items: center; position: fixed; right: 55px; top: 177px">
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-row style="margin-top: 20px">
        <el-col :span="24" >
          <el-card style="margin-bottom: 20px">
            <el-table-bar fixed :static="true">
              <el-table
                :key="tableKey"
                :data="dataList"
                fit
                :highlight-current-row="true"
                height="610"

              >
                <el-table-column
                  v-for="item of defaultHeader"
                  :key="item.label"
                  :label="item.label"
                  :align="item.align ? item.align : 'left'"
                  :min-width="item.width ? item.width : 'auto'"
                  :prop="item.prop"
                  :show-overflow-tooltip="true"
                >
                  <template slot-scope="scope">
                    
                    <!-- <template v-if="item.label === '课节名称'">
                      <span v-if="scope.row.title">
                        {{ scope.row.title }}
                      </span>
                      <span v-else>--</span>
                    </template>
                    <template v-else-if="item.label === '课时'">
                      <span v-if="scope.row.comsumeAmount">
                        {{ scope.row.comsumeAmount }}
                      </span>
                      <span v-else>--</span>
                    </template>
                    <span v-else>{{ scope.row[item.prop] }}</span> -->
                    <span >{{ scope.row[item.prop] }}</span>

                  </template>
                </el-table-column>
              </el-table>
            </el-table-bar>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageInfo.pageNum"
              :page-sizes="pageInfo.pageSizes"
              :page-size="pageInfo.pageSize"
              :layout="pageInfo.layout"
              :total="pageInfo.total"
            ></el-pagination>
          </el-card>
        </el-col>
      </el-row>
    <!-- </el-card> -->
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
import {getCourseMemberList, leaveCourse} from "@/api/businesscourse/course";

// 默认展示列
const baseColSetting = [
  {
    label: "姓名",
    prop: "userName",
    state: true,
    align: "left",
    width: "80",
  },
  {
    label: "报名时间",
    prop: "createTime",
    state: true,
    align: "center",
    width: "150",
  },
  {
    label: "状态",
    prop: "status",
    state: true,
    align: "center",
    width: "100",
  },
  
];
export default {
  components: { TextButton },
  props:{
    id:{
      type: String,
      default:()=>{}
    }
  },
  data() {
    return {
      dataList: [],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      tableKey:1
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  created(){
  },
  methods: {
    // 获取在读学员列表
    async getListHandler(id) {
      let responseData = await getCourseMemberList({
        courseId:this.id, //必传
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        // ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.dataList = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler(this.id)

    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.getListHandler(this.id)

    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getListHandler(this.id)

    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/container.scss";
@import "@/style/table.scss";
/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
.is-current {
  background-color: #eee;
}
</style>
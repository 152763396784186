var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.drawer,
        direction: _vm.direction,
        "before-close": _vm.handleClose,
        size: _vm.size,
      },
      on: {
        "update:visible": function ($event) {
          _vm.drawer = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "0 20px" } },
        [
          _c(
            "el-row",
            [
              _c("el-col", { staticClass: "item-title" }, [
                _c("span", [_vm._v("课程名称：")]),
                _c("span", { staticClass: "item-text2" }, [
                  _vm._v(" " + _vm._s(_vm.item.title)),
                ]),
              ]),
              _c("el-col", { staticClass: "item-title" }, [
                _c("span", [_vm._v("课程分类：")]),
                _c("span", { staticClass: "item-text2" }, [
                  _vm._v(" " + _vm._s(_vm.item.courseGoodsName)),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-tabs",
                {
                  staticClass: "tb-tabs",
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "课程目录", name: "Info" } },
                    [_c("Info", { ref: "Info", attrs: { id: _vm.id } })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "在读班级", name: "Class" } },
                    [_c("Class", { ref: "Class", attrs: { id: _vm.id } })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "在读学员", name: "Student" } },
                    [_c("Student", { ref: "Student", attrs: { id: _vm.id } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="table">
    <!-- 表格 -->
    <!-- <el-card shadow="never"> -->
      <div slot="header" class="flex" style="align-items: center; position: fixed; right: 55px; top: 177px">
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-row style="margin-top: 20px">
        <el-card style="margin-bottom: 20px">
          <el-table-bar fixed :static="true">
            <el-table
              :key="tableKey"
              :data="dataList"
              fit
              :highlight-current-row="true"
              height="610"
            >
              <el-table-column
                v-for="item of defaultHeader"
                :key="item.label"
                :label="item.label"
                :align="item.align ? item.align : 'left'"
                :min-width="item.width ? item.width : 'auto'"
                :prop="item.prop"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <template v-if="item.label === '课节名称'">
                    <span v-if="scope.row.title">
                      {{ scope.row.title }}
                    </span>
                    <span v-else>--</span>
                  </template>
                  <template v-else-if="item.label === '课时'">
                    <span v-if="scope.row.comsumeAmount">
                      {{ scope.row.comsumeAmount }}
                    </span>
                    <span v-else>--</span>
                  </template>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-table-bar>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNum"
            :page-sizes="pagination.pageSizes"
            :page-size="pagination.pageSize"
            :layout="pagination.layout"
            :total="pagination.total"
          ></el-pagination>
        </el-card>
      </el-row>
    <!-- </el-card> -->
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
// 默认展示列
const baseColSetting = [
  {
    label: "课次",
    prop: "sortIndex",
    state: true,
    align: "left",
    width: "100",
  },
  {
    label: "课节名称",
    prop: "title",
    state: true,
    align: "left",
    width: "100",
  },
  {
    label: "课时",
    prop: "comsumeAmount",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "上课时长",
    prop: "standardMinutes",
    state: true,
    align: "center",
    width: "100",
  },
];
import {
  getCourseUnitList,
  getLessonInfoByClassId,
  editLesson,
} from "@/api/senate/class";

export default {
  components: { TextButton },
  props: {
    id: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      dataList: [],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      tableKey: 1,
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },

  methods: {
    // 获取课节 列表
    async getCourseUnitLists() {
      const res = await getCourseUnitList({
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
        courseId: this.id,
        needCount: true,
        // needPage: true,
      });
      if (res.state === "success") {
        this.dataList = res.body.list;
        this.pagination.total = res.body.total;
        this.dataList.forEach((item,index) => {
          if(this.pagination.pageNum > 1) {
            item.sortIndex = (this.pagination.pageNum - 1) * this.pagination.pageSize + index + 1
          }else{
            item.sortIndex = index + 1
          }
        })                            
      }
    },

    search() {
      this.pagination.pageNum = 1;
      this.getCourseUnitLists();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.getCourseUnitLists();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getCourseUnitLists();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/container.scss";
@import "@/style/table.scss";
/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
.is-current {
  background-color: #eee;
}
</style>
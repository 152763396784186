<template>
  <div>
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="课程名称：">
              <el-select
                filterable
                clearable
                :loading="false"
                v-model="searchForm.courseName"
                placeholder="请选择课程名称"
              >
                <el-option
                  v-for="item in courseOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="课程账号类型：">
              <el-select v-model="searchForm.courseGoodsName" placeholder="请选择课程账号类型">
                <el-option
                  v-for="item in select"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="searchgetList">查询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card shadow="never" style="overflow-y: scroll; height: 600px">
      <div slot="header" class="flex" style="align-items: center">
        <span>课程数据</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
          :data="tableData"
          style="max-height: calc(100vh - 270px); overflow-y: auto"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '课程名称'">
                <el-tooltip :content="scope.row.title" placement="top">
                  <span class="text-oneline">
                    {{ scope.row.title }}
                  </span>
                </el-tooltip>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button
                  style="margin-left: 8px"
                  type="text"
                  @click="openDetailModal(scope.row)"
                  >课程详情</el-button
                >
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
    <!-- </el-card> -->
    <CourseDetailModal ref="detail" title="课程详情" />
  </div>
</template>
<script>
import { getCourseLists } from "@/api/businesscourse/course";
import { getGoodsList } from "@/api/businesscourse/index";
import CourseDetailModal from "./components/CourseDetailModal";
import TextButton from "@/components/TextButton";
import {
  getListCourseOnly as getCourseList,
} from "@/api/businesscourse/course";

// 默认展示列

const baseColSetting = [
  {
    label: "课程名称",
    prop: "title",
    state: true,
    align: "left",
    // sortable: true,
    width: "100",
  },
  {
    label: "课程分类",
    prop: "catalogTitle",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "课时类型",
    prop: "courseGoodsName",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "所属分组",
    prop: "courseGroup",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "课程账号总数",
    prop: "amount",
    state: true,
    // sortable: true,
    align: "center",
    width: "150",
  },
  {
    label: "课节数",
    prop: "unitAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "在读班级数",
    prop: "classAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "在读学员数",
    prop: "studentAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: { CourseDetailModal, TextButton },
  data() {
    return {
      courseOptions: [],
      tableKey: 1,
      title: "",
      size: "default",
      select:[],
      tableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 14,
        needCount: true,
      },
      searchForm: { courseName: null, courseGoodsName: null },
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getListHandler();
    this.getList()
    this.getCourseList()
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    getCourseList() {
      getCourseList({
        needCount: true,
        pageNum: 1,
        pageSize: 1000,
      }).then((res) => {
        this.courseOptions = res.body.list.map((i) => ({
          value: i.title,
          label: i.title,
        }));
      });
    },
    getList() {
      getGoodsList().then((res) => {
        if (res.state == "success") {
          this.select = res.body.list.map((i) => ({
            value: i.courseGoodsName,
          }));
          this.$emit("setOptions", this.select);
        }
      });
    },
    /**
     * 获取列表
     */
    async getListHandler() {
      let responseData = await getCourseLists({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;

        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        courseName: null,
        courseGoodsName: null,
      };
      this.getListHandler();
    },
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.$refs.detail.initData(item);
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;
        this.$refs.detail.handleClick();
      });
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    searchgetList(){
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/container.scss";
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
}
</style>

<template>
  <div class="table">
    <!-- 表格 -->
    <!-- <el-card shadow="never"> -->
    <div
      slot="header"
      class="flex"
      style="align-items: center; position: fixed; right: 55px; top: 177px"
    >
      <div class="flex_1"></div>
      <text-button
        icon="el-icon-refresh"
        contentTit="刷新"
        @click.native="search"
      ></text-button>
      <text-button
        icon="el-icon-setting"
        contentTit="列设置"
        :colSetting="colSetting"
        @update="update"
        :ispopover="true"
        :baseColSetting="baseColSetting"
      ></text-button>
    </div>
    <el-row style="margin-top: 20px">
      <el-col :span="24">
        <el-card style="margin-bottom: 20px">
          <el-table-bar fixed :static="true">
            <el-table
              :key="tableKey"
              :data="dataList"
              fit
              :highlight-current-row="true"
              height="610"
            >
              <el-table-column
                v-for="item of defaultHeader"
                :key="item.label"
                :label="item.label"
                :align="item.align ? item.align : 'left'"
                :min-width="item.width ? item.width : 'auto'"
                :prop="item.prop"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <template v-if="item.label === '班级名称'">
                    <span class="column-link" @click="classManage(scope.row)">{{
                      scope.row.name
                    }}</span>
                  </template>
                  <template v-else-if="item.label === '班级类型'">
                    <span v-if="scope.row.type">{{ scope.row.type }}</span>
                    <span v-else>--</span>
                  </template>
                  <template v-else-if="item.label === '上课进度'">
                    <span
                      >{{ Number(scope.row.finished) }}/{{
                        scope.row.total
                      }}</span
                    >
                  </template>
                  <template v-else-if="item.label === '最近排课日期'">
                    <span v-if="scope.row.start_time && scope.row.end_time">
                      {{ scope.row.start_time | formatDateStart
                      }}{{ scope.row.end_time | formatDateEnd }}
                    </span>
                    <span v-else>--</span>
                  </template>
                  <template v-else-if="item.label === '课程名称'">
                    <span v-if="scope.row.courseName">
                      {{ scope.row.courseName }}
                    </span>
                    <span v-else>--</span>
                  </template>
                  <!-- <template v-else-if="item.label === '状态'">
                    <span
                      class="span-state"
                      :style="
                        'backgroundColor' +
                        ':' +
                        getStateColor(scope.row.status)
                      "
                    ></span>
                    <span v-text="scope.row.status"></span>
                  </template> -->
                  <template v-else-if="item.label === '结业日期'">
                    <span
                      v-if="
                        !scope.row.finished_date ||
                        scope.row.finished_date === '0001-01-01' ||
                        scope.row.finished_date === '1900-01-01'
                      "
                      >未结业</span
                    >
                    <span v-else>{{ scope.row.finished_date }}</span>
                  </template>
                  <template v-else-if="item.label === '操作'">
                    <el-button type="text" @click="classManage(scope.row)" style="margin-right:5px"
                      >管理</el-button
                    >
                    <el-popconfirm
                      @onConfirm="confirmStatus(scope.row)"
                      @confirm="confirmStatus(scope.row)"
                      title="确定要删除吗？"
                    >
                      <el-button type="text" slot="reference">删除</el-button>
                    </el-popconfirm>
                  </template>
                  <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-table-bar>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNum"
            :page-sizes="pagination.pageSizes"
            :page-size="pagination.pageSize"
            :layout="pagination.layout"
            :total="pagination.total"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!-- </el-card> -->
  </div>
</template>

<script>
import TextButton from "@/components/TextButton";
import getStateColor from "@/mixins/getStateColor";

import {
  getClassAll,
  delClass,
  getProgress,
  addClassData,
  copyDoubleCla,
} from "@/api/merchantClass";
// 默认展示列
const baseColSetting = [
  {
    label: "课程名称",
    prop: "courseName",
    state: true,
    align: "left",
    width: "50",
  },
  {
    label: "班级名称",
    prop: "name",
    state: true,
    align: "center",
    width: "50",
  },
  {
    label: "班级类型",
    prop: "type",
    state: true,
    align: "center",
    width: "50",
  },
  {
    label: "开班日期",
    prop: "open_date",
    state: true,
    sortable: true,
    align: "center",
    width: "50",
  },
  {
    label: "上课进度",
    prop: "",
    state: true,
    align: "center",
    width: "50",
  },
  {
    label: "最近排课日期",
    prop: "",
    state: true,
    sortable: true,
    align: "center",
    width: "80",
  },

  {
    label: "状态",
    prop: "status",
    state: true,
    align: "center",
    width: "50",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "80",
  },
];
export default {
  mixins: [getStateColor],

  components: { TextButton },
  props: {
    id: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      dataList: [],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
      pagination: {
        pageNum: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      tableKey: 1,
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  created() {},
  methods: {
    // 获取全部班级列表
    async getClassAll(id) {
      this.dataList = [];
      const res = await getClassAll({
        courseId: this.id,
        pageNum: this.pagination.pageNum,
        pageSize: this.pagination.pageSize,
        needCount: true,
      });
      const dataList = res.body.list;
      this.pagination.total = res.body.total;

      dataList.forEach((item, index) => {
        item.finished = "";
        // item.total = "";
        this.getProgress(item.class_id, index);
      });
      this.dataList = dataList;
    },

    // 更新 查询
    search() {
      this.pagination.pageNum = 1;
      this.getClassAll();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.getClassAll(this.id);
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getClassAll(this.id);
    },
    // 管理班级
    classManage(row) {
      this.$router.push({
        path: "/merchantClass/class-detail/${" + row.class_id + "}",
        query: {
          classID: row.class_id,
          courseId: row.courseId,
          title: row.name,
        },
      });
    },
    // 获取班级上课进度
    getProgress(id, idx) {
      getProgress({
        classId: id,
      }).then((res) => {
        this.dataList[idx].finished = res.body.finished;
        this.dataList[idx].total = res.body.total;
      });
    },
    // 删除班级
    // delClass(row) {
    //   this.$confirm("确定删除当前班级吗？", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(async () => {
    //       await delClass({
    //         id: row.class_id,
    //       });
    //       window.$msg("删除成功");
    //       this.getClassAll();
    //     })
    //     .catch(() => {
    //       return false;
    //     });
    // },
    confirmStatus(row) {
      delClass({
        id: row.class_id,
      }).then((res) => {
        window.$msg("删除成功");
        this.getClassAll();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/container.scss";
@import "@/style/table.scss";
/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
.is-current {
  background-color: #eee;
}
</style>
